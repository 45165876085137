import { apiSlice } from '../api/apiSlice'

/**
 * Commonly used functions that fetch data using axios.
 */

export const getUser =
  async (dispatch, errorHandler, logout, userName) => {
    if (errorHandler === undefined) {
      throw new Error('getUser: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getUser.initiate
    const result = await dispatch(fromSlice(userName))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getArea =
  async (dispatch, errorHandler, logout) => {
    if (errorHandler === undefined) {
      throw new Error('getArea: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getArea.initiate
    const result = await dispatch(fromSlice())
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getBaseNet =
  async (dispatch, errorHandler, logout) => {
    if (errorHandler === undefined) {
      throw new Error('getBaseNet: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getBaseNet.initiate
    const result = await dispatch(fromSlice())
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getEvaluationNet =
  async (dispatch, errorHandler, logout) => {
    if (errorHandler === undefined) {
      throw new Error('getEvaluationNet: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getEvaluationNet.initiate
    const result = await dispatch(fromSlice())
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getScenarios =
  async (dispatch, errorHandler, logout) => {
    if (errorHandler === undefined) {
      throw new Error('getScenarios: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getScenarios.initiate
    const result = await dispatch(fromSlice())
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getScenario =
  async (dispatch, errorHandler, logout, scenarioId) => {
    if (errorHandler === undefined) {
      throw new Error('getScenario: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getScenario.initiate
    const result = await dispatch(fromSlice(scenarioId))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getSimulation =
  async (dispatch, errorHandler, logout, scenarioId) => {
    if (errorHandler === undefined) {
      throw new Error('getSimulation: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getSimulation.initiate
    const result = await dispatch(fromSlice(scenarioId))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getDifferences =
  async (dispatch, errorHandler, logout) => {
    if (errorHandler === undefined) {
      throw new Error('getDifferences: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getDifferences.initiate
    const result = await dispatch(fromSlice())
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const getDifference =
  async (dispatch, errorHandler, logout, minuendId, subtrahendId) => {
    if (errorHandler === undefined) {
      throw new Error('getDifference: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.getDifference.initiate
    const payload = { minuendId, subtrahendId }
    const result = await dispatch(fromSlice({ payload }))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const postScenarios =
  async (dispatch, errorHandler, logout, scenarioName, templateId) => {
    if (errorHandler === undefined) {
      throw new Error('postScenarios: No error handler defined')
    }
    const body = { name: scenarioName, template: templateId }
    const fromSlice = apiSlice.endpoints.postScenarios.initiate
    const result = await dispatch(fromSlice({ body }))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result.data
  }

export const postSimulate =
  async (dispatch, errorHandler, logout, scenarioId) => {
    if (errorHandler === undefined) {
      throw new Error('postSimulate: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.postSimulate.initiate
    const payload = { scenarioId }
    const result = await dispatch(fromSlice(payload))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const postDifference =
  async (dispatch, errorHandler, logout, minuendId, subtrahendId) => {
    if (errorHandler === undefined) {
      throw new Error('postDifference: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.postDifference.initiate
    const payload = { minuendId, subtrahendId }
    const result = await dispatch(fromSlice(payload))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const patchScenario =
  async (dispatch, errorHandler, logout, scenarioId, body) => {
    if (errorHandler === undefined) {
      throw new Error('patchScenario: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.patchScenario.initiate
    const result = await dispatch(fromSlice({ scenarioId, body }))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const deleteScenario =
  async (dispatch, errorHandler, logout, scenarioId) => {
    if (errorHandler === undefined) {
      throw new Error('deleteScenario: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.deleteScenario.initiate
    const result = await dispatch(fromSlice(scenarioId))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const deleteSimulation =
  async (dispatch, errorHandler, logout, scenarioId) => {
    if (errorHandler === undefined) {
      throw new Error('deleteSimulation: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.deleteSimulation.initiate
    const result = await dispatch(fromSlice(scenarioId))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }

export const deleteDifference =
  async (dispatch, errorHandler, logout, minuendId, subtrahendId) => {
    if (errorHandler === undefined) {
      throw new Error('deleteDifference: No error handler defined')
    }
    const fromSlice = apiSlice.endpoints.deleteDifference.initiate
    const payload = { minuendId, subtrahendId }
    const result = await dispatch(fromSlice(payload))
    if (result.error !== undefined) {
      errorHandler(result.error, logout)
      return
    }
    return result
  }
