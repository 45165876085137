import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { setVisibleLayerIdAction, deleteScenarioAction, deleteTrafficAction, deleteDifferenceAction } from '../../../actions/defaultActions'
import { deleteDifference, deleteScenario, deleteSimulation } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { styled } from '@mui/material/styles'
import CustomCheckbox from '../../CustomCheckbox'
import ControlButton from '../../ControlButton'
import useMapFeatureHandlers from '../../map/useMapFeatureHandlers'
import { Colors } from '../../constants/Colors'

const ScenarioEntry = ({ map, logout, scenario }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const differenceMaps = useSelector((state) => state.differenceMaps)

  // Local state
  const [confirmDeleteMode, setConfirmDeleteMode] = useState(false)

  const {
    enterEditMode,
    exitEditMode
  } = useMapFeatureHandlers(map, differenceMaps, trafficMaps, logout)

  const onCheckboxChanged = (e, scenario) => {
    const checkbox = e.target
    const makeVisible = checkbox.checked
    const newVisibleLayerId = makeVisible ? scenario.layerId : null
    dispatch(setVisibleLayerIdAction(newVisibleLayerId, map, visibleLayerId, roadPropertyStyles))
  }

  const deleteNetwork = async (scenario) => {
    // Remove DifferenceMaps bound to that Scenario
    const deleteDifferencePromises = differenceMaps.map((differenceMap) => {
      if (differenceMap.minuendId === scenario.id || differenceMap.subtrahendId === scenario.id) {
        return deleteDifference(
          dispatch,
          defaultErrorHandling,
          logout,
          differenceMap.minuendId,
          differenceMap.subtrahendId
        ).then(() => {
          map.removeLayer(differenceMap.layerId)
          map.removeSource(differenceMap.sourceId)
          dispatch(deleteDifferenceAction(differenceMap.id))
        })
      }
      return null
    }).filter(Boolean) // Filter undefined values (when if condition is not met)

    // Remove TrafficMaps bound to that Scenario
    const deleteTrafficPromises = trafficMaps.map((trafficMap) => {
      if (trafficMap.scenarioId === scenario.id) {
        return deleteSimulation(dispatch, defaultErrorHandling, logout, trafficMap.scenarioId)
          .then(() => {
            map.removeLayer(trafficMap.layerId)
            map.removeSource(trafficMap.sourceId)
            dispatch(deleteTrafficAction(trafficMap.id))
          })
      }
      return null
    }).filter(Boolean) // Filter undefined values (when if condition is not met)

    // Wait for all differenceMaps and trafficMaps to be deleted
    await Promise.all([...deleteDifferencePromises, ...deleteTrafficPromises])

    // Remove Scenario
    await deleteScenario(dispatch, defaultErrorHandling, logout, scenario.id)
    map.removeLayer(scenario.layerId)
    map.removeSource(scenario.sourceId)
    dispatch(deleteScenarioAction(scenario.id))
  }

  const showEditModeButtons = scenario.editable && scenario.layerId === visibleLayerId

  return (
    <div key={scenario.id}>
      <CheckboxWrapper>
        <CustomCheckbox
          id={scenario.id}
          label={scenario.name}
          value={scenario.layerId}
          checked={scenario.layerId === visibleLayerId}
          onChange={(e) => onCheckboxChanged(e, scenario)}
          disabled={editMode.active !== null}
          sx={{ padding: '3px 9px 3px 9px' }}
        />
      </CheckboxWrapper>

      {showEditModeButtons && (
        editMode.active
          ? (
          <SaveButtonWrapper>
            <ControlButton
              width='120px'
              height='32px'
              onClick={(e) => exitEditMode(e, dispatch)}
              text='Speichern'
            />
            </SaveButtonWrapper>
            )
          : (
          <EditButtonWrapper>
            <ControlButton
              width="120px"
              height="32px"
              onClick={(e) => {
                if (confirmDeleteMode) {
                  deleteNetwork(scenario, dispatch) // Final delete confirmation
                } else {
                  enterEditMode(e, scenario, dispatch, roadPropertyStyles)
                }
              }}
              backgroundColor={confirmDeleteMode ? Colors.Alert : undefined}
              color={confirmDeleteMode ? 'white' : 'inherit'}
              text={confirmDeleteMode ? 'Löschen' : 'Bearbeiten'}
              tooltip={confirmDeleteMode ? 'Entgültig löschen' : 'Bearbeitungsmodus aktivieren'}
            />
          </EditButtonWrapper>
            )
      )}

      {showEditModeButtons && (
        <ControlButton
          disabled={editMode.active !== null}
          tooltip={editMode.active
            ? undefined
            : (confirmDeleteMode ? 'Löschen abbrechen' : 'Netz löschen') }
          onClick={() => {
            if (confirmDeleteMode) {
              setConfirmDeleteMode(false) // Cancel delete
            } else {
              setConfirmDeleteMode(true) // Enter delete confirmation mode
            }
          }}
          icon={confirmDeleteMode ? 'close' : 'delete'}
          width="50px"
          height="32px"
        />
      )}
    </div>
  )
}

ScenarioEntry.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  scenario: PropTypes.object.isRequired
}

const CheckboxWrapper = styled('div')({
  display: 'inline',
  paddingRight: '10px'
})

const SaveButtonWrapper = styled('div')({
  display: 'inline',
  paddingRight: '10px'
})

const EditButtonWrapper = styled('div')({
  display: 'inline',
  paddingRight: '10px'
})

export default ScenarioEntry
