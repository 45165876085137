import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setEditModeAction } from '../../../actions/defaultActions'
import { RelationModifications } from '../../constants/RelationModifications'
import RoadProperties from './RoadProperties'
import { addRelationColor, RelationColors, NetworkColors } from '../../constants/Colors'
import { patchScenario } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import EditRelationBox from './EditRelationBox'
import AddRelationDialog from './AddRelationDialog'
import { WayModifications } from '../../constants/WayModifications'

export const setPaintColor = (
  map,
  editMode,
  selectColor,
  hoveredColor,
  defaultColor = ['get', 'relationColor']
) => {
  const color = [
    'case',
    ['boolean', ['feature-state', 'hovered'], false],
    hoveredColor,
    ['boolean', ['feature-state', 'selected'], false], // highest priority
    selectColor,
    ['boolean', ['feature-state', 'clicked'], false], // To keep the clicked relation color
    NetworkColors.Clicked,
    defaultColor
  ]
  map.setPaintProperty(editMode.scenario.layerId, 'line-color', color)
}

export const resetPaintColor = (map, editMode, roadPropertyStyles, styleKey) => {
  const style = roadPropertyStyles.find(style => style.key === styleKey)
  map.setPaintProperty(editMode.scenario.layerId, 'line-color', style.colors)
}

export const applyToMapSource = (map, editMode, selectedWayIds, unSelectedWayIds = []) => {
  const source = map.getSource(editMode.scenario.sourceId)
  const sourceData = source._data
  const updatedFeatures = addRelationColor({
    type: 'FeatureCollection',
    features: getUpdatedFeatures(map, editMode, selectedWayIds, unSelectedWayIds)
  })
  source.setData({
    ...sourceData,
    features: updatedFeatures.features
  })
}

const getUpdatedFeatures = (map, editMode, selectedWayIds, unSelectedWayIds) => {
  const sourceData = map.getSource(editMode.scenario.sourceId)._data

  return sourceData.features.map(feature => {
    const wayId = feature.properties['@id']
    if (!selectedWayIds.includes(wayId) && !unSelectedWayIds.includes(wayId)) {
      return feature
    }

    // Add or remove relation to/from ways
    const propertiesClone = { ...feature.properties } // clone as properties cannot be modified
    if (
      editMode.active === RelationModifications.AddWays ||
      editMode.active === RelationModifications.AddWaysToNewRelation
    ) {
      const relation = {
        osmId: editMode.relationEdit.clicked.relationId,
        tags: editMode.relationEdit.clicked.tags
      }
      const relations = feature.properties.relations || []
      propertiesClone.relations = relations.concat(relation)
    } else if (editMode.active === RelationModifications.RemoveWays ||
      editMode.active === RelationModifications.DeleteRelation
    ) {
      propertiesClone.relations = feature.properties.relations.filter(
        rel => rel.osmId !== editMode.relationEdit.clicked.relationId
      )
      if (propertiesClone.relations.length === 0) {
        delete propertiesClone.relations // required as property check is used in paint style
      }
    } else if (editMode.active === WayModifications.CloseWays) {
      if (selectedWayIds.includes(wayId)) {
        propertiesClone.unified = 'closed'
      } else {
        delete propertiesClone.unified
      }
    }

    return {
      ...feature,
      properties: propertiesClone
    }
  })
}

const EditRelation = ({ map, logout }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  // Local state
  const [open, setOpen] = useState(false)
  const [draft, setDraft] = useState({ name: '' })

  const onAddWays = () => {
    const modificationMode = editMode.active === RelationModifications.CreateRelation
      ? RelationModifications.AddWaysToNewRelation
      : RelationModifications.AddWays
    switchModeAndResetModification(modificationMode)
    setPaintColor(map, editMode, RelationColors.Add, RelationColors.HoveredAdd)
  }

  const onRemoveWays = () => {
    switchModeAndResetModification(RelationModifications.RemoveWays)
    setPaintColor(map, editMode, RelationColors.Remove, RelationColors.HoveredRemove)
  }

  const onDeleteRelation = () => {
    switchModeAndResetModification(RelationModifications.DeleteRelation)
  }

  const onDeleteRelationCancel = () => {
    switchModeAndResetModification(RelationModifications.SelectRelation)
  }

  const onDeleteRelationConfirm = async () => {
    // We just delete all ways from the relation. This also delete the relation in the backend
    // if the relation was created by the user, not imported from OSM.
    const changeset = getChangeset(editMode.relationEdit.modification.selectedWayIds)
    await patchScenario(dispatch, defaultErrorHandling, logout, editMode.scenario.id, changeset)

    applyToMapSource(map, editMode, editMode.relationEdit.clicked.wayIds)

    // Reset view simlar to `onCancel`
    const newRelation = { relationId: null, wayIds: [], tags: null }
    switchModeAndClickedRelation(RelationModifications.SelectRelation, newRelation, true)
    resetPaintColor(map, editMode, roadPropertyStyles, RoadProperties.relations.key)
  }

  /**
   * Is called when the user completes their way changes for a relation in:
   * - `RelationModification.AddWays`
   * - `RelationModification.AddWaysToNewRelation`
   * - `RelationModification.RemoveEays`
   */
  const saveChangedWays = async () => {
    const changeset = getChangeset(editMode.relationEdit.modification.selectedWayIds)
    await patchScenario(dispatch, defaultErrorHandling, logout, editMode.scenario.id, changeset)

    applyToMapSource(map, editMode, editMode.relationEdit.modification.selectedWayIds)

    resetModeAndUpdateRelationWayIds(newWayIds())

    resetPaintColor(map, editMode, roadPropertyStyles, RoadProperties.relations.key)
  }

  const onCancel = () => {
    const modificationMode = editMode.active === RelationModifications.AddWaysToNewRelation
      ? RelationModifications.CreateRelation
      : RelationModifications.EditRelation
    switchModeAndResetModification(modificationMode)

    resetPaintColor(map, editMode, roadPropertyStyles, RoadProperties.relations.key)
  }

  const unselectRelation = () => {
    dispatch(setEditModeAction({
      ...editMode,
      // reset from RelationModifications.EditRelation / RelationModifications.CreateRelation
      active: RelationModifications.SelectRelation,
      relationEdit: {
        ...editMode.relationEdit,
        clicked: { relationId: null, wayIds: [], tags: null }
      }
    }, map, editMode))
  }

  /**
   * Allows the user to add ways to the relation draft to be created.
   *
   * @param {*} e The submit button event which triggered this method.
   */
  const enterCreateRelationMode = (e) => {
    e.preventDefault()
    if (draft.name.length === 0) {
      alert('Bitte geben Sie einen Namen ein.')
      return
    }
    // Reset popup fields and hide to prevent user interaction
    setOpen(false)
    setDraft({ name: '' })

    // Smaller than max java and mongo db int but large enought to not hit existing OSM relation ids
    const newRandomRelationId = Math.floor(100000000 + Math.random() * 900000000)
    const newRelation = {
      // relationId: -1, // We could also use `-1` similar as in OsmChange to indicate that
      // this relation needs a fresh id assigned by the server. But for now we use a random
      // generated large id - the database ensures elementId like `relation/12345678` is unique.
      relationId: newRandomRelationId,
      wayIds: [],
      tags: { type: 'route', route: 'bicycle', name: draft.name }
    }
    switchModeAndClickedRelation(RelationModifications.CreateRelation, newRelation, false)
  }

  /**
   * Shows a popup for further input information.
   */
  const openAddRelationDialog = () => {
    setOpen(true)
  }

  /**
   * Updates the draft name of the relation to be added.
   */
  const onAddRelationNameChanged = (e) => {
    setDraft({ name: e.target.value })
  }

  /**
   * Completes a way change for a relation and updates its ways.
   *
   * @param {*} wayIds The new wayIds to be set.
   */
  const resetModeAndUpdateRelationWayIds = (wayIds) => {
    dispatch(setEditModeAction({
      ...editMode,
      active: RelationModifications.EditRelation, // Also for CreateRelation, as it's now created
      scenarioChanged: true, // To remove the simulation on edit mode save
      relationEdit: {
        ...editMode.relationEdit,
        clicked: {
          ...editMode.relationEdit.clicked,
          wayIds
        },
        modification: { hoveredWayId: null, selectedWayIds: [] }
      }
    }, map, editMode))
  }

  /**
   * Needs to be one call or else the second call will undo the first call.
   *
   * @param {*} modificationMode A string of RelationModification options or null.
   * @param {*} relation The new relation to be set.
   * @param {*} scenarioChanged `true` if the simulation needs to be deleted.
   */
  const switchModeAndClickedRelation = (modificationMode, relation, scenarioChanged) => {
    dispatch(setEditModeAction({
      ...editMode,
      active: modificationMode,
      scenarioChanged,
      relationEdit: {
        ...editMode.relationEdit,
        clicked: relation,
        modification: { hoveredWayId: null, selectedWayIds: [] }
      }
    }, map, editMode))
  }

  const switchModeAndResetModification = (modificationMode) => {
    dispatch(setEditModeAction({
      ...editMode,
      active: modificationMode,
      relationEdit: {
        ...editMode.relationEdit,
        modification: { hoveredWayId: null, selectedWayIds: [] }
      }
    }, map, editMode))
  }

  const getChangeset = (selectedWayIds) => {
    const edit = editMode.relationEdit
    const elementId = edit.clicked.relationId
    const changeset = { 'element-id': 'relation/' + elementId }

    if (editMode.active === RelationModifications.AddWays) {
      changeset.added = selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    } else if (editMode.active === RelationModifications.RemoveWays ||
      editMode.active === RelationModifications.DeleteRelation
    ) {
      changeset.deleted = selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    } else if (editMode.active === RelationModifications.CreateRelation) {
      changeset.created = true // indicates that this is not an edit of an existing relation
      // default type and route for bicycle routes (required by simulation)
      changeset.edited = { type: 'route', route: 'bicycle', name: edit.clicked.tags.name }
      changeset.added = selectedWayIds.map(wayId => {
        return { type: 'way', ref: wayId, role: '' }
      })
    }
    return changeset
  }

  const newWayIds = () => {
    const modification = editMode.relationEdit.modification

    switch (editMode.active) {
      // Both modes only add ways
      case RelationModifications.AddWays:
      case RelationModifications.AddWaysToNewRelation: {
        const updatedWayIds = [
          ...editMode.relationEdit.clicked.wayIds,
          ...modification.selectedWayIds
        ]
        return updatedWayIds
      }
      case RelationModifications.RemoveWays: {
        return editMode.relationEdit.clicked.wayIds.filter(wayId => {
          return !modification.selectedWayIds.includes(wayId)
        })
      }
      default: {
        throw new Error('Unknown modification mode: ' + editMode.active)
      }
    }
  }

  return (
    <div>
      <EditRelationBox
        modificationMode={editMode.active}
        relationEdit={editMode.relationEdit}
        onAdd={onAddWays}
        onRemove={onRemoveWays}
        onSave={saveChangedWays}
        onCancel={onCancel}
        onClose={unselectRelation}
        onCreate={openAddRelationDialog}
        onDelete={onDeleteRelation}
        onDeleteCancel={onDeleteRelationCancel}
        onDeleteConfirm={onDeleteRelationConfirm}
      />
      <AddRelationDialog
        open={open}
        setOpen={setOpen}
        draft={draft}
        onNameChanged={onAddRelationNameChanged}
        onSubmit={enterCreateRelationMode}
      />
    </div>
  )
}

EditRelation.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default EditRelation
