import { createAction } from '@reduxjs/toolkit'
import { differenceLayerPrefix, evaluationLayerPrefix, trafficLayerPrefix } from '../components/IdHelper'
import RoadProperties from '../components/sidebar/scenario/RoadProperties'
import { WayModifications } from '../components/constants/WayModifications'
import { RelationModifications } from '../components/constants/RelationModifications'
import { activeModificationMode } from '../components/map/useMapFeatureHandlers'

/**
 * Creates an actions for updating the editMode in the Redux store.
 *
 * @param {*} editMode the new editModeState
 */
export const setEditModeAction =
  createAction('SET_EDIT_MODE', (editMode, map, previousEditMode) => {
    if (editMode.active /* || previousEditMode.active */) {
      const sourceId = editMode.scenario
        ? editMode.scenario.sourceId
        : previousEditMode.scenario.sourceId

      // When hovered, clicked or selected wayIds are changed, update the map

      // Single way selection mode
      const active = editMode.active
      const previousActive = previousEditMode.active
      const singleWaySelection =
        active === WayModifications.SelectWay ||
        active === WayModifications.EditWay
      // Also check previousEditMode so the previously selected ways are unselected
      const multiWaySelection =
        previousActive === WayModifications.CloseWays ||
        active === WayModifications.CloseWays
      const relationSelection =
        active === RelationModifications.SelectRelation ||
        active === RelationModifications.EditRelation ||
        active === RelationModifications.DeleteRelation ||
        // Without this clicked relations stay clicked when switching away from relation view
        previousActive === RelationModifications.SelectRelation ||
        previousActive === RelationModifications.EditRelation ||
        previousActive === RelationModifications.DeleteRelation
      const relationWayChange =
        active === RelationModifications.AddWaysToNewRelation ||
        active === RelationModifications.AddWays ||
        active === RelationModifications.RemoveWays ||
        // Without this added/removed ways stay clicked when saving and re-entering AddWays mode
        previousActive === RelationModifications.AddWaysToNewRelation ||
        previousActive === RelationModifications.AddWays ||
        previousActive === RelationModifications.RemoveWays
      if (singleWaySelection) {
        updateHoveredWay(map, editMode, previousEditMode, sourceId)
        updateClickedWay(map, editMode, previousEditMode, sourceId)
      }
      if (multiWaySelection) {
        updateHoveredWay(map, editMode, previousEditMode, sourceId)
        updateSelectedWays(map, editMode, previousEditMode, sourceId)
      }
      if (relationSelection) {
        updateHoveredRelationWays(map, editMode, previousEditMode, sourceId)
        updateClickedRelationWays(map, editMode, previousEditMode, sourceId)
      }
      if (relationWayChange) {
        updateHoveredRelationModificationWays(map, editMode, previousEditMode, sourceId)
        updateSelectedRelationModificationWays(map, editMode, previousEditMode, sourceId)
      }
    }

    return {
      // TODO: We might update the whole editMode here, consider only applying the changes
      // Also: We might execute the whole logic above for all editMode changes
      // Instead, only insert the changes via dispatch(setEditMode({hoveredId: newId}))
      // And then only apply the map updates here if ('hoveredId' in payload.editModeUpdate)
      // We might also first want to switch to `createSlice` instead of creating actions manually
      // and then move this action logic to the reducers, see cyface-de/web-app
      payload: editMode
    }
  })

const updateHoveredWay = (map, editMode, previousEditMode, sourceId) => {
  const previousHoveredWayId = previousEditMode.wayEdit.hoveredWayId
  const hoveredWayId = editMode.wayEdit.hoveredWayId
  switchFeatureState(map, sourceId, previousHoveredWayId, hoveredWayId, 'hovered')
}

const updateClickedWay = (map, editMode, previousEditMode, sourceId) => {
  const previousClickedWayId = previousEditMode.wayEdit.clickedWayId
  const clickedWayId = editMode.wayEdit.clickedWayId
  switchFeatureState(map, sourceId, previousClickedWayId, clickedWayId, 'clicked')
}

const updateSelectedWays = (map, editMode, previousEditMode, sourceId) => {
  // Select ways to be added or removed from CloseWays
  const previousSelectedWayIds = previousEditMode.wayEdit.modification.selectedWayIds
  const selectedWayIds = editMode.wayEdit.modification.selectedWayIds
  switchFeaturesState(map, sourceId, previousSelectedWayIds, selectedWayIds, 'selected')
}

const updateHoveredRelationWays = (map, editMode, previousEditMode, sourceId) => {
  const previousHoveredWayIds = previousEditMode.relationEdit.hovered.wayIds
  const hoveredWayIds = editMode.relationEdit.hovered.wayIds
  switchFeaturesState(
    map,
    sourceId,
    previousHoveredWayIds,
    hoveredWayIds,
    'hovered'
  )
}

const updateClickedRelationWays = (map, editMode, previousEditMode, sourceId) => {
  const previousClickedRelationWayIds = previousEditMode.relationEdit.clicked.wayIds
  const clickedRelationWayIds = editMode.relationEdit.clicked.wayIds
  switchFeaturesState(
    map,
    sourceId,
    previousClickedRelationWayIds,
    clickedRelationWayIds,
    'clicked'
  )
}

const updateHoveredRelationModificationWays = (map, editMode, previousEditMode, sourceId) => {
  const previousHoveredModificationWayId =
    previousEditMode.relationEdit.modification.hoveredWayId
  const hoveredModificationWayId = editMode.relationEdit.modification.hoveredWayId
  switchFeatureState(
    map,
    sourceId,
    previousHoveredModificationWayId,
    hoveredModificationWayId,
    'hovered'
  )
}

const updateSelectedRelationModificationWays = (map, editMode, previousEditMode, sourceId) => {
  const previousSelectedWayIds = previousEditMode.relationEdit.modification.selectedWayIds
  const selectedWayIds = editMode.relationEdit.modification.selectedWayIds
  switchFeaturesState(
    map,
    sourceId,
    previousSelectedWayIds,
    selectedWayIds,
    'selected'
  )
}

const switchFeatureState = (map, sourceId, previousWayId, newWayId, propertyName) => {
  if (previousWayId !== newWayId) {
    // Reset previous way
    if (previousWayId !== null) {
      map.setFeatureState({ source: sourceId, id: previousWayId }, { [propertyName]: false })
    }
    // Highlight new way
    if (newWayId !== null) {
      map.setFeatureState({ source: sourceId, id: newWayId }, { [propertyName]: true })
    }
  }
}

const switchFeaturesState = (map, sourceId, previousWayIds, newWayIds, propertyName) => {
  // Reset previous ways
  previousWayIds.forEach(previousWayId => {
    if (!newWayIds.includes(previousWayId)) {
      map.setFeatureState({ source: sourceId, id: previousWayId }, { [propertyName]: false })
    }
  })
  // Highlight new ways
  newWayIds.forEach(newWayId => {
    if (!previousWayIds.includes(newWayId)) {
      map.setFeatureState({ source: sourceId, id: newWayId }, { [propertyName]: true })
    }
  })
}

export const setVisibleLayerIdAction =
  createAction('SET_VISIBLE_LAYER_ID', (
    visibleLayerId,
    map,
    previousLayerId,
    roadPropertyStyles
  ) => {
    if (previousLayerId === undefined) {
      throw Error('PreviousLayerId is undefined')
    }
    if (map === null) {
      throw Error('SET_VISIBLE_LAYER_ID: Map is null')
    }
    // Hide previous layer
    if (previousLayerId !== null) {
      map.setLayoutProperty(previousLayerId, 'visibility', 'none')
    }
    // Make new layer visible and update colors
    if (visibleLayerId !== null) {
      if (!visibleLayerId.startsWith(trafficLayerPrefix) &&
        !visibleLayerId.startsWith(differenceLayerPrefix) &&
        !visibleLayerId.startsWith(evaluationLayerPrefix)) {
        const activeStyle = roadPropertyStyles.find(s => s.active)
        map.setPaintProperty(visibleLayerId, 'line-color', activeStyle.colors)
        map.setPaintProperty(visibleLayerId, 'line-opacity', activeStyle.opacity)
        map.setPaintProperty(visibleLayerId, 'line-dasharray', activeStyle.lineDashArray)
      }
      map.setLayoutProperty(visibleLayerId, 'visibility', 'visible')
    }
    return {
      payload: {
        visibleLayerId
      }
    }
  })

export const setRoadPropertyStyleActiveAction = createAction(
  'SET_ROAD_PROPERTY_STYLE_ACTIVE',
  (activeKey, map, previouslyActiveKey, editMode, dispatch) => {
    const relationsKey = RoadProperties.relations.key

    const modificationMode = activeModificationMode(activeKey)
    if (
      // If switching away from `relations` view, unset clicked relation
      previouslyActiveKey === relationsKey &&
      activeKey !== relationsKey &&
      editMode.relationEdit.clicked.relationId != null
    ) {
      const oldClickedRelation = editMode.relationEdit.clicked
      const newEditModeState = {
        ...editMode,
        active: modificationMode,
        relationEdit: {
          ...editMode.relationEdit,
          clicked: { relationId: null, wayIds: [], tags: {} }
        }
      }
      dispatch(setEditModeAction(newEditModeState, map, editMode))

      // Update map
      const sourceId = newEditModeState.scenario.sourceId
      if (oldClickedRelation.relationId) {
        oldClickedRelation.wayIds.forEach(wayId => {
          map.setFeatureState(
            { source: sourceId, id: wayId },
            { hovered: false, clicked: false, selected: false }
          )
        })
      }
    } else if (editMode.active !== null) {
      // Update `editMode.active` modification type if we are already in edit mode
      dispatch(setEditModeAction({
        ...editMode,
        active: modificationMode
      }, map, editMode))
    }

    return {
      payload: activeKey
    }
  }
)

export const setDevModeAction = createAction('SET_DEV_MODE')

export const setRoadPropertyStylesAction = createAction('SET_ROAD_PROPERTY_STYLES')

export const setMapStyleAction = createAction('SET_MAP_STYLE')

export const deleteTrafficAction = createAction('DELETE_TRAFFIC_MAP')

export const deleteDifferenceAction = createAction('DELETE_DIFFERENCE_MAP')

export const addScenarioAction = createAction('ADD_SCENARIO')

export const addTrafficAction = createAction('ADD_TRAFFIC_MAP')

export const addDifferenceAction = createAction('ADD_DIFFERENCE_MAP')

export const deleteScenarioAction = createAction('DELETE_SCENARIO')
