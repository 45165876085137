import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { styled } from '@mui/material/styles'
import TermsOfUse from './TermsOfUse'
import {
  setLocalStorage, autoLogin, getConfig, NoHashQueryStringUtils,
  termsAccepted, loggedIn, storeTokens, mockAuth, asyncFetchOAuthConfiguration,
  getLocalStorage
} from './utils.js'
import { showSnackbar } from '../SnackbarService.js'
import {
  RedirectRequestHandler, LocalStorageBackend, DefaultCrypto, AuthorizationRequest
} from '@openid/appauth'
import logo from '../../resources/logo_black.png'
import ControlButton from '../ControlButton.js'
import Footer from '../Footer'
import { Grid, Box } from '@mui/material'
import { defaultErrorHandling } from '../ErrorHandlingHelpers'
import { LocalStorage } from '../constants/LocalStorage'

const STATUS_SUBMITTING = 'SUBMITTING'

const authorizationHandler = new RedirectRequestHandler(
  new LocalStorageBackend(),
  new NoHashQueryStringUtils(),
  window.location,
  new DefaultCrypto()
)

/**
 * Logs in automatically or offers a login form.
 */
const LoginForm = () => {
  // Stateless hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { message } = useParams()

  // Local state
  const [showTerms, setShowTerms] = useState(false)
  const [status, setStatus] = useState(null)

  /**
   * Show terms or attempt auto-login when user enters page.
   */
  useEffect(() => {
    // If user just logged in and was forwarded by Callback.js, show terms
    if (loggedIn() && !termsAccepted()) {
      setShowTerms(true)
    } else {
      autoLogin(navigate, '/map', '/', dispatch, logout)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Handler for on-logout clicks by the user.
   *
   * This is a copy from `Dashboard.js` and should be kept in sync.
   */
  const logout = useCallback(() => {
    // Clear local storage
    const errorTrackingAccepted = getLocalStorage(LocalStorage.ErrorTrackingAccepted)
    const configuration = getLocalStorage(LocalStorage.AuthServiceConfiguration)
    const endSessionUrl = configuration.endSessionEndpoint
    const idToken = getLocalStorage(LocalStorage.IdToken)
    localStorage.clear()
    // Set terms-accepted flag so the used does not have to accept it again on the next login
    setLocalStorage(LocalStorage.TermsAccepted, true)
    setLocalStorage(LocalStorage.ErrorTrackingAccepted, errorTrackingAccepted)

    // Inform the auth server that the user wants to end the session
    if (mockAuth()) {
      console.log('Mocking logout ...')
      window.location.href = '/'
    } else {
      const redirectUri = getConfig().redirectUri
      const u = `${endSessionUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`
      window.location.href = u
      // The auth server will then redirect the user to `Callback` which navigates the user to `/`
    }

    // Prevent default handling of the link-click
    return false
  }, [])

  /**
   * Handler called when user interacts with the terms page.
   *
   * @param {*} termsAccepted `True` if the user accepted the terms, `false` otherwise.
   */
  const termsHandling = (termsAccepted, errorTrackingAccepted) => {
    if (termsAccepted) {
      setLocalStorage(LocalStorage.TermsAccepted, termsAccepted)
      setLocalStorage(LocalStorage.ErrorTrackingAccepted, errorTrackingAccepted)
      navigate('/map')
    } else {
      showSnackbar(
        'Um diesen Service zu nutzen müssen Sie die die Datenschutzerklärung akzeptieren.'
      )
      setShowTerms(false)
    }
  }

  /**
   * Authenticates with the API using the provided credentials from the login form.
   *
   * @param {*} e the form submit event containing the credentials
   */
  const login = async (e) => {
    if (e !== null) {
      e.preventDefault()
    }
    setStatus(STATUS_SUBMITTING)

    if (mockAuth()) {
      console.log('Mocking login ...')
      setLocalStorage(LocalStorage.AuthServiceConfiguration, { mocked: true })
      storeTokens({
        expiresIn: (new Date().getTime() / 1000.0) + 3600,
        accessToken: 'eyMockAccessToken',
        refreshToken: 'eyMockRefreshToken'
      })
      window.location.href = '/Callback?code=mock'
      return
    }

    try {
      const response = await asyncFetchOAuthConfiguration()

      // Authorize user by forwarding the user to the OAuth server
      const config = getConfig()
      await authorizationHandler
        .performAuthorizationRequest(response, new AuthorizationRequest({
          client_id: config.clientId,
          redirect_uri: config.redirectUri,
          scope: 'openid email profile',
          response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
          state: undefined
        }))

      // After successful auth the user is forwarded to `Callback.js`
    } catch (error) {
      console.log(error)
      setStatus(null)
      defaultErrorHandling(error, () => {
        // No need to cleanup as the user is not logged in (dashboard not loaded)
        // This handler should never be called, as we handle case 401 a few lines above!
        throw new Error('Unexpected call of logout handler')
      })
    }
  }

  return (
    <div>

      {showTerms
      // Hide login form when terms are shown (to hide button in background on low-width screens)
        ? <TermsOfUse handleTerms={termsHandling} />
        : <StyledContainer>
          <form action="POST" onSubmit={login}>
            <center>
              <img
                src={logo}
                alt='Logo'
                style={{ marginBottom: '20px' }}
                width={160}
              />
            </center>

            <Grid container justifyContent="center" alignItems="center" direction="column">
              <Grid item xs={12}>
                <Box
                  sx={{
                    color: 'green',
                    padding: '5px',
                    display: message ? 'block' : 'none'
                  }}>
                  {'Unbekannter Nachrichten-Code'}
                </Box>

                <ControlButton
                  type="submit"
                  text="Anmelden"
                  disabled={status === STATUS_SUBMITTING}
                  icon="login"
                  width="400px"
                  sx={{ mt: 1 }}
                />
              </Grid>
            </Grid>
          </form>
        </StyledContainer>
      }

      <Footer right="calc((100vw - 238px)/2)" />
    </div>
  )
}

/**
 * The width if aligned with the Captcha(removed) width (300 + 2*10.5 input-padding).
 */
const StyledContainer = styled('div')({
  position: 'fixed',
  top: 'calc(50vh - 39px)',
  left: '50vw',
  width: 'max(50vw, 321px)',
  maxWidth: '500px',
  transform: 'translate(-50%, -50%)',
  fontFamily: "'Helvetica', 'Arial', sans-serif",
  a: {
    color: 'rgb(63, 135, 48)'
  }
})

LoginForm.propTypes = {
}

export default LoginForm
