import React, { useRef, useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { addBaseNet, addDifferences, addScenarios, addSimulations, OVERVIEW_ZOOM_LEVEL } from './MapBoxHelpers'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { addTrafficAction, addScenarioAction, addDifferenceAction } from '../../actions/defaultActions.js'
import { debug } from '../login/utils.js'
import { getBaseNet, getScenarios, getDifferences, getScenario, getDifference, getSimulation, getArea } from '../DataApi.js'
import { setTrafficColor, setDifferenceColor } from '../colorRanges.js'
import { useSnackbarContext } from '../SnackbarContext.js'
import TrafficLegend from '../sidebar/trafficMaps/TrafficLegend.js'
import DifferenceLegend from '../sidebar/differenceMaps/DifferenceLegend.js'
import ScenarioLegend from '../sidebar/scenario/ScenarioLegend.js'
import { Status } from '../constants/Status.js'
import { defaultErrorHandling } from '../ErrorHandlingHelpers.js'
import { getDiffId, differenceLayerPrefix, getLayerId, getTrafficId, trafficLayerPrefix, evaluationLayerPrefix } from '../IdHelper.js'
import BackgroundSwitcher from './BackgroundSwitcher.js'

/**
 * The initial zoom of the map.
 */
const initialZoom = 15

const MapContainer = ({
  map,
  setMap,
  logout,
  mobileView,
  accessToken,
  trafficThresholds,
  differenceThresholds,
  evaluationThresholds,
  addFeature
}) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // The document to render the Mapbox map in
  const mapContainer = useRef(null)

  // Local state
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  // The current zoom level of the map
  const [zoomLevel, setZoomLevel] = useState(initialZoom)
  const zoomRef = useRef()
  zoomRef.current = zoomLevel

  // Redux state
  const visibleLayerId = useSelector(state => state.visibleLayerId)

  /**
   * Effects which depend on no state/prop (i.e. only executed on un-/mount).
   *
   * The first part is called when the component is inserted into the DOM.
   * The returned function is called when the component is removed from the DOM.
   *
   * Sets up the mapbox-map including the onClick etc. handlers.
   */
  useEffect(() => {
    if (map) return // initialize map only once

    // Required as it's not allowed to useEffect(async ()...
    const initializeMap = async () => {
      // Load data from backend
      const { area, baseNet, scenarios, simulations, differences } = await loadData()
      enqueueSnackbar('Füge Daten zur Karte hinzu')

      // Setup map if not already initialized
      mapboxgl.accessToken = accessToken
      if (!map && mapContainer.current) {
        const newMap = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/light-v11?optimize=true',
          // This way we zoom to the municipality from germany not from space
          center: [10.0, 51.0], // Rough center of Germany
          zoom: 5 // Rough initial zoom level for Germany
        })

        // Move to Area bounding box
        newMap.fitBounds([[area.minLon, area.minLat], [area.maxLon, area.maxLat]], {
          padding: 20, // Optionally: padding around the bounding box
          maxZoom: 15 // Optionally: max zoom level only valid during "fitBounds" call
        })

        // Add zoom control
        const navControl = new mapboxgl.NavigationControl()
        newMap.addControl(navControl, 'top-left')

        // Add search bar
        newMap.addControl(
          new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            language: 'de-DE',
            mapboxgl
          })
        )

        // Add Scale
        const mapScale = new mapboxgl.ScaleControl({
          maxWidth: 120,
          unit: 'metric'
        })
        newMap.addControl(mapScale)

        // Set ZoomSpeed
        newMap.scrollZoom.setWheelZoomRate(1 / 100)

        newMap.on('zoomend', async () => {
          // Only update when zoom level crosses the overview zoom level
          if ((zoomRef.current < OVERVIEW_ZOOM_LEVEL && newMap.getZoom() >= OVERVIEW_ZOOM_LEVEL) ||
              (zoomRef.current > OVERVIEW_ZOOM_LEVEL && newMap.getZoom() <= OVERVIEW_ZOOM_LEVEL)) {
            setZoomLevel(newMap.getZoom())
          }
        })

        // Set map state when loaded + set state data
        newMap.on('load', async (e) => {
          // Add to Map
          addBaseNet(newMap, baseNet, addFeature)
          addScenarios(newMap, scenarios, addFeature)
          await addSimulations(newMap, simulations, addFeature)
          await addDifferences(newMap, differences, addFeature)

          // Set the map after everything else is set up
          setIsMapLoaded(true)
          setMap(newMap)

          // Add to Redux store
          dispatch(addScenarioAction({
            id: baseNet.scenarioId,
            name: 'Basisnetz',
            editable: false,
            layerId: getLayerId(baseNet.scenarioId),
            sourceId: getLayerId(baseNet.scenarioId)
          }))
          for (const scenario of scenarios) {
            dispatch(addScenarioAction({
              id: scenario._id,
              name: scenario.name,
              editable: true,
              layerId: getLayerId(scenario._id),
              sourceId: getLayerId(scenario._id)
            }))
          }
          for (const simulation of simulations) {
            dispatch(addTrafficAction({
              id: simulation.id,
              name: simulation.name,
              layerId: getLayerId(simulation.id),
              sourceId: getLayerId(simulation.id),
              scenarioId: simulation.scenarioId
            }))
          }
          for (const difference of differences) {
            const diffId = getDiffId(difference.minuendId, difference.subtrahendId)
            dispatch(addDifferenceAction({
              id: diffId,
              name: difference.name,
              layerId: getLayerId(diffId),
              sourceId: getLayerId(diffId),
              minuendId: difference.minuendId,
              subtrahendId: difference.subtrahendId
            }))
          }

          closeSnackbar()
        })
      }
    }

    if (mapContainer.current) initializeMap({ setMap, mapContainer })
    // eslint-disable-next-line
  }, [/* map */]) // effect depends on no state/props: only run on un-/mount, not re-render

  const loadData = async () => {
    enqueueSnackbar('Lade Basisnetz')

    const [areaResult, baseNetResult, scenarioIdsResult, differencesIdsResult] = await Promise.all([
      getArea(dispatch, defaultErrorHandling, logout),
      getBaseNet(dispatch, defaultErrorHandling, logout),
      getScenarios(dispatch, defaultErrorHandling, logout),
      getDifferences(dispatch, defaultErrorHandling, logout)
    ])

    const area = areaResult.data
    const baseNet = baseNetResult.data
    const scenarioIds = scenarioIdsResult.data
    const differencesIds = differencesIdsResult.data

    const baseSimulation = await loadSimulation('Basisnetz', baseNet.scenarioId)
    const simulations = baseSimulation !== null ? [baseSimulation] : []

    if (scenarioIds.error) {
      if (debug()) {
        console.log(scenarioIds.error)
      }
      enqueueSnackbar('Fehler beim Laden der Scenarios')
    }

    const scenariosPromises = scenarioIds.identifiers.map(async (scenarioId, index) => {
      enqueueSnackbar(`Lade Plannetz ${index + 1} von ${scenarioIds.identifiers.length}`)
      const scenario = (await getScenario(dispatch, defaultErrorHandling, logout, scenarioId)).data
      const simulation = await loadSimulation(scenario.name, scenarioId)
      if (simulation !== null) {
        simulations.push(simulation)
      }
      return scenario
    })

    const scenarios = await Promise.all(scenariosPromises)

    const differencesPromises = differencesIds.identifiers.map(async (differencesId) => {
      const { minuendId, subtrahendId } = differencesId
      const differenceResult =
        await getDifference(dispatch, defaultErrorHandling, logout, minuendId, subtrahendId)
      const difference = differenceResult.data
      if (difference.data.status === Status.Finished) {
        const minuendScenario = scenarios.find(s => s._id === minuendId)
        const subtrahendScenario = scenarios.find(s => s._id === subtrahendId)
        if ((minuendId !== baseNet.scenarioId && !minuendScenario) ||
            (subtrahendId !== baseNet.scenarioId && !subtrahendScenario)) {
          throw new Error('Minuend or subtrahend name for differences entry not found.')
        }
        const minuendName = minuendId !== baseNet.scenarioId ? minuendScenario.name : 'Basisnetz'
        const subtrahendName = subtrahendId !== baseNet.scenarioId
          ? subtrahendScenario.name
          : 'Basisnetz'
        const data = await setDifferenceColor(difference.data.data)
        const diffId = getDiffId(minuendId, subtrahendId)
        return {
          // The web-app still uses minuend/subtrahend ids. But the API now also has difference ids.
          id: diffId,
          name: `${subtrahendName}; ${minuendName}`, // The customer expects this order
          data,
          minuendId,
          subtrahendId
        }
      }
    })

    const differences = await Promise.all(differencesPromises)

    return {
      area,
      baseNet,
      scenarios,
      simulations,
      differences: differences.filter(diff => diff !== undefined) // Filter out null results
    }
  }

  const loadSimulation = async (scenarioName, scenarioId) => {
    const simulateResult = await getSimulation(dispatch, defaultErrorHandling, logout, scenarioId)
    const simulate = simulateResult.data
    const simulation = simulate.data
    if (simulation.simulationResult && simulation.simulationResult.status === Status.Finished) {
      const traffic = await setTrafficColor(await simulation.simulationResult.data)
      const trafficId = getTrafficId(simulation._id)
      return {
        // The API only has an id for the scenario, not the simulation
        id: trafficId,
        name: scenarioName,
        data: traffic,
        scenarioId
      }
    }
    return null
  }

  const isTrafficShown = visibleLayerId !== null && visibleLayerId.startsWith(trafficLayerPrefix)
  const isDiffShown = visibleLayerId !== null && visibleLayerId.startsWith(differenceLayerPrefix)
  const isEvaluationShown =
    visibleLayerId !== null && visibleLayerId.startsWith(evaluationLayerPrefix)
  const isScenarioShown =
    visibleLayerId !== null && !isTrafficShown && !isDiffShown && !isEvaluationShown

  return (
    <div>
      <Mapbox
        ref={(el) => { mapContainer.current = el }}
        $headerHeight={'70px'}
        $sidebarSize={mobileView ? '0px' : '350px'}
      />

      { isMapLoaded ? <BackgroundSwitcher map={map} /> : '' }

      {
        // Show Legend and Layer Switcher
        isScenarioShown
          ? <ScenarioLegend map={map} logout={logout} />
          : isTrafficShown && trafficThresholds != null
            ? <TrafficLegend trafficThresholds={trafficThresholds} />
            : isDiffShown && differenceThresholds != null
              ? <DifferenceLegend differenceThresholds={differenceThresholds} />
              : isEvaluationShown && evaluationThresholds != null
                ? <DifferenceLegend differenceThresholds={evaluationThresholds} />
                : null
      }
    </div>
  )
}

MapContainer.propTypes = {
  map: PropTypes.object,
  setMap: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  accessToken: PropTypes.string.isRequired,
  trafficThresholds: PropTypes.array,
  differenceThresholds: PropTypes.array,
  evaluationThresholds: PropTypes.array,
  addFeature: PropTypes.func.isRequired
}

const Mapbox = styled('div')(({ $headerHeight, $sidebarSize }) => ({
  position: 'fixed', // relative to the viewport
  bottom: 0,
  right: 0,
  height: `calc(100% - ${$headerHeight})`,
  width: `calc(100% - ${$sidebarSize})`,
  zIndex: 0
}))

export default MapContainer
