import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled, LinearProgress, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { addTrafficAction, setVisibleLayerIdAction } from '../../../actions/defaultActions'
import { getLocalStorage, getWebsocketApiUrl } from '../../login/utils'
import { getSimulation, postSimulate } from '../../DataApi.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { setTrafficColor } from '../../colorRanges.js'
import { useSnackbarContext } from '../../SnackbarContext'
import ControlButton from '../../ControlButton'
import Dropdown from '../Dropdown'
import { Endpoints } from '../../constants/Endpoints'
import { LocalStorage } from '../../constants/LocalStorage.js'
import { Status } from '../../constants/Status'
import { MapLayers } from '../../constants/MapLayers.js'
import { getLayerId, getTrafficId } from '../../IdHelper.js'
import { evaluationScenarioName } from '../scenario/AddScenario.js'

const AddTrafficMap = ({ map, logout, addFeature }) => {
  // Redux hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbarContext()

  // Redux state
  const trafficMaps = useSelector((state) => state.trafficMaps)
  const visibleLayerId = useSelector((state) => state.visibleLayerId)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)
  const scenarios = useSelector((state) => state.scenarios)

  // Local state
  const [selectedDropdownLayerObject, setSelectedDropdownLayerObject] = useState(null)
  const [progress, setProgress] = useState(null)

  const handleSubmit = async () => {
    const layerObject = selectedDropdownLayerObject
    const scenarioId = layerObject.scenarioId
    const scenarioLayerId = layerObject.id
    const scenarioLayerName = layerObject.name
    if (trafficMaps.find(element => element.scenarioId === scenarioId) !== undefined) {
      alert('Für dieses Netz existiert bereits eine Verkehrsmengensimulation.')
      setSelectedDropdownLayerObject(null)
      return
    }

    await simulate(scenarioId, scenarioLayerId, scenarioLayerName)

    setSelectedDropdownLayerObject(null)
  }

  const simulate = async (layerScenarioId, scenarioLayerId, scenarioLayerName) => {
    try {
      await postSimulate(dispatch, defaultErrorHandling, logout, layerScenarioId).data
      subscribeToProgress(layerScenarioId, scenarioLayerId, scenarioLayerName)
    } catch (error) {
      defaultErrorHandling(error, logout)
    }
  }

  const subscribeToProgress = async (layerScenarioId, scenarioLayerId, scenarioLayerName) => {
    enqueueSnackbar('Starte Simulation ...')

    // Subscribe to simulation progress websocket - TOOD: see cyface app> useSocketManager
    // await asyncRefreshTokenIfRequired() - should new be automatic
    const subProtocols = ['Bearer', getLocalStorage(LocalStorage.AccessToken)]
    const endpoint = getWebsocketApiUrl() + Endpoints.SimlationProgress(layerScenarioId)
    const socket = new WebSocket(endpoint, subProtocols)

    socket.onmessage = async (event) => {
      closeSnackbar() // close "starting simulation ..."
      const isNumber = Number.isInteger(Number(event.data))
      if (isNumber) {
        const newProgress = parseInt(event.data, 10)
        setProgress(newProgress)
      } else {
        console.log('Unrecognized simulation progress message: ' + event.data)
        enqueueSnackbar('Simulation fehlgeschlagen')
      }
      if (event.data === '100') {
        setProgress(null) // Reset progress when done
        try {
          // Load traffic
          const simulate = (await getSimulation(
            dispatch,
            defaultErrorHandling,
            logout,
            layerScenarioId
          )).data
          const simulationResult = simulate.data.simulationResult
          if (simulationResult.status === Status.Running) {
            throw Error('Simulation still runnning, no results yet!')
          }
          const traffic = setTrafficColor(simulationResult.data)

          // Update map
          const trafficId = getTrafficId(scenarioLayerId)
          const trafficLayerId = getLayerId(trafficId)
          addFeature(map, trafficLayerId, traffic, MapLayers.TrafficMap)

          // Update redux store
          const trafficMap = {
            id: trafficId,
            name: scenarioLayerName,
            layerId: trafficLayerId,
            sourceId: trafficLayerId,
            scenarioId: layerScenarioId
          }
          dispatch(addTrafficAction(trafficMap))

          dispatch(
            setVisibleLayerIdAction(trafficMap.layerId, map, visibleLayerId, roadPropertyStyles)
          )
        } catch (error) {
          if (error.response && error.response.status === 401) {
            enqueueSnackbar('Fehler: Nicht autorisiert. Bitte melden Sie sich erneut an.')
          } else {
            enqueueSnackbar('Ein Fehler ist aufgetreten: ' + error.message)
          }
        }
      }
    }
    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
      enqueueSnackbar('Verbindungsfehler: Simulationfortschritt nicht erreichbar')
    }
  }

  /**
   * Updates internal state when the dropdown selection changes.
   */
  const onChange = (layerObject) => {
    setSelectedDropdownLayerObject(layerObject)
  }

  return (
    <StyleBox>
      <StyleHead>Simulation</StyleHead>

      { progress === null && (
        <Dropdown
          selectedLayerObject={selectedDropdownLayerObject}
          layerObjects={scenarios.filter(s => s.name !== evaluationScenarioName).map(scenario => {
            const layerObject = {}
            layerObject.id = scenario.id
            layerObject.name = scenario.name
            layerObject.layerId = scenario.id
            layerObject.scenarioId = scenario.id
            return layerObject
          })}
          onChangeHandler={onChange} />
      )}

        {selectedDropdownLayerObject === null
          ? ''
          : <SimulateButtonWrapper>
            <ControlButton
              id='startSimulationButton'
              text="Simulation starten"
              onClick={() => handleSubmit()}
              disabled={selectedDropdownLayerObject === null}
              width='100%'
              icon="play_arrow" />
          </SimulateButtonWrapper>}

      {progress !== null && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </StyleBox>
  )
}

const StyleBox = styled('div')({
  border: '1px solid lightgrey',
  width: '94%',
  margin: '10px',
  padding: '10px',
  fontSize: '14pt',
  whiteSpace: 'nowrap'
})

const StyleHead = styled('div')({
  fontSize: '15pt',
  color: '#222A35',
  paddingBottom: '10px'
})

const SimulateButtonWrapper = styled('div')({
  paddingTop: '10px'
})

AddTrafficMap.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  addFeature: PropTypes.func.isRequired
}

export default AddTrafficMap
