import { useState, useRef } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { setRoadPropertyStylesAction, setEditModeAction, deleteTrafficAction, deleteDifferenceAction } from '../../actions/defaultActions'
import { deleteDifference, deleteSimulation } from '../DataApi'
import { defaultErrorHandling } from '../ErrorHandlingHelpers'
import RoadProperties from '../sidebar/scenario/RoadProperties'
import { RelationModifications } from '../constants/RelationModifications'
import { useSelector } from 'react-redux'
import { relationInfo } from './MapBoxHelpers'
import { WayModifications } from '../constants/WayModifications'

export const activeModificationMode = (activeRoadPropertyKey) => {
  switch (activeRoadPropertyKey) {
    case RoadProperties.relations.key:
      return RelationModifications.SelectRelation
    case RoadProperties.unified.key:
    case RoadProperties.maxSpeed.key:
    case RoadProperties.roadStyleSimplified.key:
    case RoadProperties.surface.key:
      return WayModifications.SelectWay
    default:
      throw Error('Unexpected style: ' + activeRoadPropertyKey)
  }
}

const useMapFeatureHandlers = (map, differenceMaps, trafficMaps, logout) => {
  // Redux state
  const editMode = useSelector((state) => state.editMode)
  const roadPropertyStyles = useSelector((state) => state.roadPropertyStyles)

  // Local state
  // Keep a handler-references to unregister them in exitEditMode
  const [handlers, setHandlers] = useState({
    segmentMouseMoveHandler: null,
    segmentMouseLeaveHandler: null,
    segmentClickHandler: null,
    mapClickHandler: null
  })

  // To access the current version of editMode in handlers.
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  const editModeContainer = useRef()
  editModeContainer.current = editMode
  const roadPropertyStylesContainer = useRef()
  roadPropertyStylesContainer.current = roadPropertyStyles

  const isEditModeInactive = (editMode) => !editMode.active
  const isEditingWay = (editMode) => editMode.active === WayModifications.EditWay
  const isCreatingRelation = (editMode) => editMode.active === RelationModifications.CreateRelation

  const createSegmentMouseMoveHandler = (
    editModeContainer,
    dispatch,
    roadPropertyStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadPropertyStyles = roadPropertyStylesContainer.current
    if (isEditModeInactive(editMode) || isEditingWay(editMode) || isCreatingRelation(editMode)) {
      return
    }

    const activeStyleKey = roadPropertyStyles.find(style => style.active).key
    const selectedWay = e.features[0]

    switch (activeStyleKey) {
      case RoadProperties.relations.key: {
        if (
          editMode.active === RelationModifications.SelectRelation ||
          editMode.active === RelationModifications.EditRelation
        ) {
          onRelationHovered(editMode, selectedWay, dispatch)
        } else {
          if (wayNotSelectable(selectedWay.id, editMode.relationEdit, editMode.active)) {
            return
          }

          // Set cursor
          map.getCanvas().style.cursor = 'pointer'

          changeHoveredWayForRelation(editMode, selectedWay.id, dispatch)
        }
        break
      }
      default: {
        // Set cursor
        map.getCanvas().style.cursor = 'pointer'

        changeHoveredWay(editMode, selectedWay.id, dispatch)
      }
    }
  }

  const createSegmentMouseLeaveHandler = (
    editModeContainer,
    dispatch,
    roadPropertyStylesContainer
  ) => (e) => {
    const editMode = editModeContainer.current
    const roadPropertyStyles = roadPropertyStylesContainer.current
    if (isEditModeInactive(editMode) || isEditingWay(editMode) || isCreatingRelation(editMode)) {
      return
    }

    // Reset cursor
    map.getCanvas().style.cursor = ''

    const active = roadPropertyStyles.find(style => style.active).key

    switch (active) {
      case RoadProperties.relations.key: {
        if (
          editMode.active === RelationModifications.SelectRelation ||
          editMode.active === RelationModifications.EditRelation
        ) {
          changeHoveredRelation(editMode, null, dispatch)
        } else {
          changeHoveredWayForRelation(editMode, null, dispatch)
        }
        break
      } default: {
        changeHoveredWay(editMode, null, dispatch)
      }
    }
  }

  const createSegmentClickHandler = (
    editModeContainer,
    dispatch
  ) => (e) => {
    const editMode = editModeContainer.current
    if (isEditModeInactive(editMode) || isEditingWay(editMode) || isCreatingRelation(editMode)) {
      return
    }

    const selectedWay = e.features[0]
    const selectedWayId = selectedWay.properties['@id']
    const modificationMode = editMode.active

    // Select ways to for road closure
    if (modificationMode === WayModifications.CloseWays) {
      selectWayForRoadClosure(editMode, selectedWayId, dispatch)
    } else if (modificationMode === WayModifications.SelectWay) {
      selectWayToEdit(editMode, selectedWayId, dispatch)
    } else if (
      modificationMode === RelationModifications.SelectRelation ||
      modificationMode === RelationModifications.EditRelation
    ) {
      selectRelationToEdit(editMode, selectedWay, dispatch)
    } else if (
      modificationMode === RelationModifications.AddWaysToNewRelation ||
      modificationMode === RelationModifications.AddWays ||
      modificationMode === RelationModifications.RemoveWays
    ) {
      selectWayForRelation(editMode, selectedWay, dispatch)
    } else {
      throw Error('Expected active style: ' + modificationMode)
    }
  }

  /**
   * Resets currently clicked relation.
   */
  const createMapClickHandler = (
    dispatch, editModeContainer, roadPropertyStylesContainer
  ) => (e) => {
    // disabled as this is buggy

    /* const editMode = editModeContainer.current
    const roadPropertyStyles = roadPropertyStylesContainer.current
    if (isEditModeInactive(editMode) ||
      (isEditingWay(editMode) && isModifyingRelation(editMode))) {
      return
    } */

    // Update state
    // const active = roadPropertyStyles.find(style => style.active).key
    /* if (active === RoadProperties.relations.key) {
      const newEditModeState = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          clicked: {
            relationId: null, // automatically updates legend
            wayIds: [],
            tags: {}
          }
        }
      }
      dispatch(setEditMode(newEditModeState, map, editMode))
    } */
  }

  const enterEditMode = (e, scenario, dispatch, roadPropertyStyles) => {
    const buttonClicked = e.target
    // Lock view
    setControlButtonsDisabled(true, buttonClicked)
    setDropdownsDisabled(true)
    switchStyleColors(scenario, roadPropertyStyles, dispatch)

    // If we access `onClickHandler` etc. via `this.state.onClickHandler` the handler is not found
    const segmentMouseMoveHandler = createSegmentMouseMoveHandler(
      editModeContainer,
      dispatch,
      roadPropertyStylesContainer
    )
    const segmentMouseLeaveHandler = createSegmentMouseLeaveHandler(
      editModeContainer,
      dispatch,
      roadPropertyStylesContainer
    )
    const segmentClickHandler = createSegmentClickHandler(
      editModeContainer,
      dispatch
    )
    const mapClickHandler = createMapClickHandler(
      dispatch,
      editModeContainer,
      roadPropertyStylesContainer
    )

    // Keep a handler-references to unregister them in exitEditMode
    setHandlers({
      segmentMouseMoveHandler,
      segmentMouseLeaveHandler,
      segmentClickHandler,
      mapClickHandler
    })

    // Update state
    const activeRoadPropertyKey = roadPropertyStyles.find(s => s.active).key
    const active = activeModificationMode(activeRoadPropertyKey)
    const newEditModeState = {
      active,
      scenarioChanged: false,
      scenario,
      wayEdit: {
        hoveredWayId: null,
        clickedWayId: null,
        modification: {
          selectedWayIds: []
        }
      },
      relationEdit: {
        hovered: {
          relationId: null,
          wayIds: []
        },
        clicked: {
          relationId: null,
          wayIds: []
        },
        modification: {
          hoveredWayId: null,
          selectedWayIds: []
        }
      }
    }
    dispatch(setEditModeAction(newEditModeState, map, editMode))

    // Register handler
    const layerId = scenario.layerId
    map.on('click', mapClickHandler) // register before segment click, else clicked segment is reset
    map.on('click', layerId, segmentClickHandler)
    // "mousemove" fixes hover between two segments without leaving one
    map.on('mousemove', layerId, segmentMouseMoveHandler)
    map.on('mouseleave', layerId, segmentMouseLeaveHandler)

    // Attention! `map.on('click', e => {})` is also called when we click on a feature layer!
    // Thus, don't do anyhing there which could lead to a race condition with other onclick events!
  }

  const exitEditMode = async (e, dispatch) => {
    const buttonClicked = e.target
    const { scenario } = editModeContainer.current
    switchStyleColors(scenario, roadPropertyStyles, dispatch)

    // Remove handler (avoids multi-handler-calls)
    const {
      segmentMouseMoveHandler,
      segmentMouseLeaveHandler,
      segmentClickHandler,
      mapClickHandler
    } = handlers
    const layerId = scenario.layerId
    map.off('mousemove', layerId, segmentMouseMoveHandler)
    map.off('mouseleave', layerId, segmentMouseLeaveHandler)
    map.off('click', layerId, segmentClickHandler)
    map.off('click', mapClickHandler)

    // Unhighlight edited elements on map (saved)
    const source = map.getSource(scenario.sourceId)
    const sourceData = source._data
    sourceData.features.filter(element => element.properties.edited === true).forEach(element => {
      element.properties.edited = false
    })
    source.setData(sourceData)

    // Update state
    setHandlers({
      segmentMouseMoveHandler: null,
      segmentMouseLeaveHandler: null,
      segmentClickHandler: null,
      mapClickHandler: null
    })

    const scenarioChanged = editMode.scenarioChanged
    const newEditModeState = {
      active: null,
      scenarioChanged: false,
      scenario: null,
      wayEdit: {
        hoveredWayId: null,
        clickedWayId: null,
        modification: {
          selectedWayIds: []
        }
      },
      relationEdit: {
        hovered: {
          relationId: null,
          wayIds: []
        },
        clicked: {
          relationId: null,
          wayIds: []
        },
        modification: {
          hoveredWayId: null,
          selectedWayIds: []
        }
      }
    }
    dispatch(setEditModeAction(newEditModeState, map, editMode))

    if (scenarioChanged) {
      // Remove DifferenceMaps bound to that Scenario
      for (const differenceMap of differenceMaps) {
        if (differenceMap.minuendId === scenario.id ||
          differenceMap.subtrahendId === scenario.id) {
          await deleteDifference(
            dispatch,
            defaultErrorHandling,
            logout,
            differenceMap.minuendId,
            differenceMap.subtrahendId
          )
          map.removeLayer(differenceMap.layerId)
          map.removeSource(differenceMap.sourceId)
          dispatch(deleteDifferenceAction(differenceMap.id))
        }
      }

      // Remove TrafficMaps bound to that Scenario
      for (const trafficMap of trafficMaps) {
        if (trafficMap.scenarioId === scenario.id) {
          await deleteSimulation(
            dispatch,
            defaultErrorHandling,
            logout,
            trafficMap.scenarioId
          )
          map.removeLayer(trafficMap.layerId)
          map.removeSource(trafficMap.sourceId)
          dispatch(deleteTrafficAction(trafficMap.id))
        }
      }
    }

    // Unlock view
    setControlButtonsDisabled(false, buttonClicked)
    setDropdownsDisabled(false)
  }

  const switchStyleColors = (scenario, roadPropertyStyles, dispatch) => {
    // Swap the colors for each style
    const newStyles = roadPropertyStyles.map(style => {
      const newStyle = cloneDeep(style) // original object may not be changed
      newStyle.colors = style.alternativeColors
      newStyle.opacity = style.alternativeOpacity
      newStyle.alternativeColors = style.colors
      newStyle.alternativeOpacity = style.opacity
      if (style.active) {
        map.setPaintProperty(scenario.layerId, 'line-color', newStyle.colors)
        map.setPaintProperty(scenario.layerId, 'line-opacity', newStyle.opacity)
      }
      return newStyle
    })
    dispatch(setRoadPropertyStylesAction(newStyles))
  }

  // Only allow ways which are (not) in relationEdit.clicked.wayIds for remove (add)
  const wayNotSelectable = (selectedWayId, editModeRelation, active) => {
    const addingWays =
      active === RelationModifications.AddWays ||
      active === RelationModifications.CreateRelation
    const removingWays = active === RelationModifications.RemoveWays
    const wayInRelation = editModeRelation.clicked.wayIds.includes(selectedWayId)
    return (addingWays && wayInRelation) || (removingWays && !wayInRelation)
  }

  const onRelationHovered = (editMode, selectedWay, dispatch) => {
    // Set cursor
    const hasRelations = ('relations' in selectedWay.properties)
    const newRelationInfo =
      hasRelations ? relationInfo(selectedWay, hasRelations)[0] : null // select first relation

    const oldHoveredRelation = editMode.relationEdit.hovered
    const sameRelationHovered = oldHoveredRelation.relationId && newRelationInfo &&
      newRelationInfo.osmId === oldHoveredRelation.relationId
    if (sameRelationHovered) {
      return // relation did not change and should still be highlighted
    }

    const clickedRelationId = editMode.relationEdit.clicked.relationId
    const hoveredIsClicked =
      newRelationInfo && clickedRelationId && newRelationInfo.osmId === clickedRelationId
    if (newRelationInfo === null || hoveredIsClicked) {
      changeHoveredRelation(editMode, null, dispatch)
      // For some reasons the cursor does not reliably change for already clicked relations
      // Thus, we currently do not support to click already clicked relations
      return // has no relations or is not clickable
    }

    if (hasRelations) {
      map.getCanvas().style.cursor = 'pointer'
    }

    // Set new hovered relation
    const relationWayIds = findWayIds(editMode, newRelationInfo.osmId)
    const newRelation = {
      relationId: newRelationInfo.osmId,
      wayIds: relationWayIds,
      tags: newRelationInfo.tags
    }
    changeHoveredRelation(editMode, newRelation, dispatch)
  }

  /**
   * Enables or disables all control buttons but one.
   *
   * @param newState True if the buttons should be disabled, false if enabled
   * @param exceptionButton The button to be kept untouched
   */
  const setControlButtonsDisabled = (newState, exceptionButton) => {
    const controlButtons = document.getElementsByClassName('controlButton')
    Array.prototype.slice.call(controlButtons)
      .filter(element => element !== exceptionButton)
      .forEach(button => {
        button.disabled = newState
      })
  }

  /**
   * Enables or disables all dropdowns.
   *
   * @param newState True if the dropdowns should be disabled, false if enabled
   */
  const setDropdownsDisabled = (newState) => {
    const buttons = document.getElementsByClassName('dropdownButton')
    Array.prototype.slice.call(buttons)
      .forEach(button => {
        button.disabled = newState
      })
  }

  const findWayIds = (editMode, relationId) => {
    const { scenario } = editMode
    const source = map.getSource(scenario.sourceId)
    const features = source._data.features
    return features.filter((feature) => {
      return ('relations' in feature.properties) &&
        feature.properties.relations.some((relation) => relation.osmId === relationId)
    }).map((feature) => feature.properties['@id'])
  }

  const selectWayForRoadClosure = (editMode, selectedWayId, dispatch) => {
    const oldSelectedWayIds = editMode.wayEdit.modification.selectedWayIds
    const unSelectWay = oldSelectedWayIds.includes(selectedWayId)
    const newWayIds = unSelectWay
      ? oldSelectedWayIds.filter(id => id !== selectedWayId)
      : [...oldSelectedWayIds, selectedWayId]
    const newEditModeState = {
      ...editMode,
      wayEdit: {
        ...editMode.wayEdit,
        modification: {
          ...editMode.wayEdit.modification,
          selectedWayIds: newWayIds
        }
      }
    }
    dispatch(setEditModeAction(newEditModeState, map, editMode))
  }

  const selectWayToEdit = (editMode, selectedWayId, dispatch) => {
    dispatch(setEditModeAction({
      ...editMode,
      active: WayModifications.EditWay,
      wayEdit: {
        ...editMode.wayEdit,
        hoveredWayId: null,
        clickedWayId: selectedWayId
      }
    }, map, editMode))
  }

  const selectRelationToEdit = (editMode, selectedWay, dispatch) => {
    const hasRelations = ('relations' in selectedWay.properties)
    if (!hasRelations) {
      return
    }
    const newRelationInfo = relationInfo(selectedWay, hasRelations)[0] // select first relation

    const relationWayIds = findWayIds(editMode, newRelationInfo.osmId)
    const clickedRelation = {
      relationId: newRelationInfo.osmId,
      wayIds: relationWayIds,
      tags: newRelationInfo.tags
    }

    changeClickedRelation(editMode, clickedRelation, dispatch)
  }

  const selectWayForRelation = (editMode, selectedWay, dispatch) => {
    if (wayNotSelectable(selectedWay.id, editMode.relationEdit, editMode.active)) {
      return
    }

    const oldSelectedWayIds = editMode.relationEdit.modification.selectedWayIds
    const unSelectWay = oldSelectedWayIds.includes(selectedWay.id)
    const newWayIds = unSelectWay
      ? oldSelectedWayIds.filter(id => id !== selectedWay.id)
      : [...oldSelectedWayIds, selectedWay.id]
    const newEditModeState = {
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        modification: {
          ...editMode.relationEdit.modification,
          selectedWayIds: newWayIds
        }
      }
    }
    dispatch(setEditModeAction(newEditModeState, map, editMode))
  }

  const changeHoveredRelation = (editMode, newRelation, dispatch) => {
    const oldRelation = editMode.relationEdit.hovered
    if (newRelationHovered(newRelation, oldRelation)) {
      const newEditMode = {
        ...editMode,
        relationEdit: {
          ...editMode.relationEdit,
          hovered: newRelation || { relationId: null, wayIds: [], tags: {} }
        }
      }
      dispatch(setEditModeAction(newEditMode, map, editMode))
    }
  }

  const changeClickedRelation = (
    editMode,
    clickedRelation,
    dispatch
  ) => {
    const newEditMode = {
      ...editMode,
      active: RelationModifications.EditRelation, // relation is clicked
      relationEdit: {
        ...editMode.relationEdit,
        hovered: { relationId: null, wayIds: [], tags: {} },
        clicked: clickedRelation
      }
    }
    dispatch(setEditModeAction(newEditMode, map, editMode))
  }

  const newRelationHovered = (newRelation, oldRelation) => {
    return newRelation == null || (newRelation && oldRelation.relationId !== newRelation.relationId)
  }

  const changeHoveredWayForRelation = (editMode, newWayId, dispatch) => {
    const newEditMode = {
      ...editMode,
      relationEdit: {
        ...editMode.relationEdit,
        modification: {
          ...editMode.relationEdit.modification,
          hoveredWayId: newWayId
        }
      }
    }
    dispatch(setEditModeAction(newEditMode, map, editMode))
  }

  const changeHoveredWay = (editMode, newWayId, dispatch) => {
    const newEditMode = {
      ...editMode,
      wayEdit: {
        ...editMode.wayEdit,
        hoveredWayId: newWayId
      }
    }
    dispatch(setEditModeAction(newEditMode, map, editMode))
  }

  return {
    enterEditMode,
    exitEditMode
  }
}

export default useMapFeatureHandlers
