import React from 'react'
import { useParams } from 'react-router'

/**
 * Handles routing errors.
 */
const RoutingError = () => {
  const param = useParams()

  return (
    <div>
      <p>{`URL /${param.errorRoute} existiert nicht`}</p>
    </div>
  )
}

export default RoutingError
