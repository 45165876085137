import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { setEditModeAction } from '../../../actions/defaultActions'
import { patchScenario } from '../../DataApi'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import EditFeatureDialog from './EditFeatureDialog'
import { WayModifications } from '../../constants/WayModifications'

const EditFeature = ({ map, logout }) => {
  // Redux hooks
  const dispatch = useDispatch()

  // Redux state
  const editMode = useSelector((state) => state.editMode)

  // Local state
  const [currentValues, setCurrentValues] = useState(null)
  const [currentChanges, setCurrentChanges] = useState({
    // Current changes. set to null after submit
    roadStyleSimplified: null,
    surface: null,
    maxSpeed: null
  })

  const active = editMode.active === WayModifications.EditWay

  const loadCurrentValues = useCallback(() => {
    if (currentValues === null) {
      const source = map.getSource(editMode.scenario.sourceId)
      const feature = source._data.features.find(
        element => element.properties['@id'] === editMode.wayEdit.clickedWayId)

      setCurrentValues({
        roadStyleSimplified: feature.properties.roadStyleSimplified,
        surface: feature.properties.surface,
        maxSpeed: feature.properties.maxSpeed
      })
    }
  }, [currentValues, map, editMode])

  useEffect(() => {
    if (active) {
      loadCurrentValues()
    }
  }, [active, loadCurrentValues])

  /**
   * Unsets the feature currently in edit mode.
   * This automatically hides this popup.
   * Do this when you completed the changes to the feature in edit mode.
   *
   * @param {*} `true` if the scenario should be set to changed. Defaults to `undefined` to not
   * change the current value (which might already be `true`!).
   */
  const resetWayIdAndMarkAsChanged = (scenarioChanged = undefined) => {
    // Unset wayId to hide the popup
    const newState = {
      ...editMode,
      active: WayModifications.SelectWay, // to reset clicked way in defaultAction
      wayEdit: {
        hoveredWayId: null,
        clickedWayId: null,
        modification: {
          selectedWayIds: []
        }
      }
    }
    // Mark scenarioChanged to delete simulation when leaving editMode
    if (scenarioChanged !== undefined) {
      newState.scenarioChanged = scenarioChanged
    }
    dispatch(setEditModeAction(newState, map, editMode))

    // Reset component state
    setCurrentChanges({
      roadStyleSimplified: null,
      surface: null,
      maxSpeed: null
    })
    setCurrentValues(null)
  }

  const persistChanges = async () => {
    const scenarioId = editMode.scenario.id
    const wayId = editMode.wayEdit.clickedWayId
    const newState = currentChanges
    // Nothing changed
    if (!hasNonNullValue(newState)) {
      resetWayIdAndMarkAsChanged()
      return
    }

    // Send changeset to API
    const edited = filterNonNullValues(newState)
    const changeset = { 'element-id': 'way/' + wayId, edited }
    await sendChanges(changeset, scenarioId)

    // Apply changes to map.source
    const source = map.getSource(editMode.scenario.sourceId)
    const sourceData = source._data
    const feature = sourceData.features.find(element => element.properties['@id'] === wayId)
    Object.assign(feature.properties, edited)
    // Highlight edited (unsaved) elements on map (dasharray does not work with feature-state)
    feature.properties.edited = true
    // Highlight unified state of the way as usually returned by the API
    feature.properties.unified = 'edited'
    // Highlight the edited attributes per way as usually returned by the API
    Object.keys(newState).forEach((key) => {
      if (newState[key] !== null) {
        if (feature.properties[key + '.original'] === undefined) {
          feature.properties[key + '.original'] = feature.properties[key]
        }
      }
    })
    source.setData(sourceData)

    resetWayIdAndMarkAsChanged(true)
  }

  const hasNonNullValue = (obj) => {
    return Object.values(obj).some(value => value !== null)
  }

  const filterNonNullValues = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] !== null) {
        acc[key] = obj[key]
      }
      return acc
    }, {})
  }

  const sendChanges = async (changeset, scenarioId) => {
    await patchScenario(dispatch, defaultErrorHandling, logout, scenarioId, changeset)
  }

  const handleFeatureChange = (e) => {
    const newValue = e.target.value
    const attributeName = e.target.name
    setCurrentChanges((prevState) => ({
      ...prevState,
      [attributeName]: newValue
    }))
  }

  if (!active) return ''

  const mergedProperties = currentValues !== null
    ? {
        roadStyleSimplified:
          currentChanges.roadStyleSimplified ?? currentValues.roadStyleSimplified,
        surface: currentChanges.surface ?? currentValues.surface,
        maxSpeed: currentChanges.maxSpeed ?? currentValues.maxSpeed
      }
    : null

  return mergedProperties !== null
    ? <EditFeatureDialog
      properties={mergedProperties}
      handleFeatureChange={handleFeatureChange}
      persistChanges={persistChanges}
      resetWayId={resetWayIdAndMarkAsChanged}
    />
    : ''
}

EditFeature.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default EditFeature
